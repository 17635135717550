<template>
  <v-dialog v-model="newDialog" persistent max-width="500">
    <v-card>
      <v-card-title>new medicine </v-card-title>
      <v-card-text>
        <v-select
          label="Medicine Version"
          :loading="loading"
          loader-height="1"
          :items="mediversionList"
          item-text="name"
          item-value="id"
          v-model="view.medicene_versionsid"
        ></v-select>
        <v-text-field label="name" dense v-model="view.name"></v-text-field>
        <v-text-field label="eml" dense v-model="view.eml"></v-text-field>
        <v-textarea
          outlined
          label="formulations"
          dense
          v-model="view.formulations"
        ></v-textarea>
        <v-text-field
          label="indications"
          dense
          v-model="view.indications"
        ></v-text-field>
        <v-text-field
          label="atc codes"
          dense
          v-model="view.atc_codes"
        ></v-text-field>
        <v-text-field
          label="combined"
          dense
          v-model="view.combined"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
  },
  data() {
    return {
      loading: false,
      responseMessage: null,
      mediversionList: [],
      view: {
        medicene_versionsid: null,
        name: null,
        eml: null,
        formulations: null,
        indications: null,
        atc_codes: null,
        combined: null,
        status: null,
      },
    };
  },
  validations: {
    view: {
      medicene_versionsid: {
        required,
      },
      name: {
        required,
      },
      formulations: {
        required,
      },
    },
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.mediversion
        .list()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.mediversionList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let payload = { state: false, data: this.view };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
