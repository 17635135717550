<template>
  <v-dialog v-model="uploadDialog" scrollable persistent>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title> Upload Medicenes </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-item-group mandatory>
              <v-container>
                <v-select
                  dense
                  style="padding-top: 5px"
                  :items="mediVersions"
                  item-value="id"
                  item-text="name"
                  label="Version"
                  v-model="version"
                ></v-select>
                <v-file-input
                  dense
                  label="Upload CSV File"
                  truncate-length="15"
                  clearable
                  show-size
                  accept=".csv, .json"
                  v-model="uploads"
                  @change="fileupload($event)"
                ></v-file-input>
              </v-container>
            </v-item-group>
          </v-card-text>
        </v-card>
        <br />
        <v-card v-show="previewTable" outlined>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="viewData"
              :items-per-page="8"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closes"> NO </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submits">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    uploadDialog: Boolean,
    subType: { type: Number, default: 0 },
    myRole: { type: Number, default: 0 },
  },
  // mixins: [Utils],
  data() {
    return {
      previewTable: false,
      responseMessage: null,
      uploads: [],
      viewData: [],
      mediVersions: [],
      headers: [
        { text: "Medicine name", value: "name" },
        { text: "EML section", value: "eml" },
        { text: "Formulations", value: "formulations" },
        { text: "ATC Codes", value: "atc_codes" },
        { text: "combined", value: "combined" },
        { text: "Indication", value: "indications" },
        { text: "Status", value: "status" },
      ],
      version: null,
      file: null,
      myfileUpload: null,
      myfile: null,
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.mediversion
        .list()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.mediVersions = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    fileupload(event) {
      // NOTE: get json from excel from => https://products.aspose.app/cells/conversion/excel-to-json
      this.myfile = new FormData();
      this.myfile.append("file", event[0]);
      this.file = event;

      const reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = (e) => {
        this.filfileUploadUpload = e.target.result;
        this.myfileUpload = e.target.result;

        // console.log("test: ", JSON.parse(this.filfileUploadUpload));
        this.viewData = JSON.parse(this.filfileUploadUpload);
        // console.log("test: ", this.viewData);
        this.previewTable = true;
      };
    },
    // csvToJson(csv) {
    //   var lines = csv.split("\n");
    //   var result = [];
    //   var headers = lines[0].replace(/[\r\n]+/gm, "").split(",");
    //   for (var i = 1; i < lines.length; i++) {
    //     var obj = {};
    //     var currentline = lines[i].replace(/[\r\n]+/gm, "").split(",");
    //     for (var j = 0; j < headers.length; j++) {
    //       obj[headers[j]] = currentline[j];
    //     }
    //     result.push(obj);
    //   }
    //   console.log("preview: ", result);
    //   this.testMe = result;
    //   this.previewTable = true;
    //   return result;
    // },
    closes() {
      let payload = { state: false };
      this.$emit("closeUpload", payload);
    },
    submits() {
      let jsonData = this.viewData;
      let medicene_versionsid = this.version;
      console.log("jsonData:", jsonData);
      let data = { jsonData, medicene_versionsid };
      //
      let payload = { state: false, data: data };
      console.log("payload", payload);
      this.$emit("submitUpload", payload);
      this.viewData = [];
    },
  },
};
</script>
