<template>
  <v-dialog v-model="editDialog" persistent max-width="600">
    <v-card :loading="loading">
      <v-card-title>edit {{ name }}</v-card-title>
      <v-card-text>
        <v-select
          label="Medicine Version"
          :loading="loading"
          loader-height="1"
          :items="mediversionList"
          item-text="name"
          item-value="id"
          v-model="view.medicene_versionsid"
        ></v-select>
        <v-text-field label="name" dense v-model="view.name"></v-text-field>
        <v-text-field label="eml" dense v-model="view.eml"></v-text-field>
        <v-textarea
          outlined
          label="formulations"
          dense
          v-model="view.formulations"
        ></v-textarea>
        <v-text-field
          label="indications"
          dense
          v-model="view.indications"
        ></v-text-field>
        <v-text-field
          label="atc codes"
          dense
          v-model="view.atc_codes"
        ></v-text-field>
        <v-text-field
          label="combined"
          dense
          v-model="view.combined"
        ></v-text-field>
        <v-text-field
          disabled
          label="combined"
          dense
          v-model="view.status"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    editDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      mediversionList: [],
      view: {
        medicene_versionsid: null,
        name: null,
        eml: null,
        formulations: null,
        indications: null,
        atc_codes: null,
        combined: null,
        status: null,
      },
    };
  },
  created() {
    this.find();
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.mediversion
        .list()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.mediversionList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    find() {
      this.loading = true;
      let self = this;
      Restful.general.medicines
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.view.medicene_versionsid = data.medicene_versionsid;
          this.view.name = data.name;
          this.view.eml = data.eml;
          this.view.formulations = data.formulations;
          this.view.indications = data.indications;
          this.view.atc_codes = data.atc_codes;
          this.view.combined = data.combined;
          this.view.status = data.status;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = true;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let payload = { id: this.myId, state: false, data: this.view };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
